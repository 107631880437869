<template>
  <div class="sort pt-4">
    <el-container class="container">
      <div class="sort-hd">
        <div class="sort-hd__hd">
          <router-link to="/">首页</router-link>
          <div class="brand-tag" @click.stop="onCheck(sortDatas['items'][0])">
            <el-icon>
              <ArrowRight />
            </el-icon>
            {{ sortDatas['title'] }}
          </div>
          <!-- <div class="brand-tag">
            <el-icon>
              <ArrowRight />
            </el-icon>
            {{ sortDatas['items'][scenes_type_id_idx]['title'] }}
          </div> -->
        </div>

        <div class="sort-hd__ft">
          <el-collapse v-model="activeNames">
            <el-collapse-item v-for="(item, idx) in scenesSortList" :title="item.title" :name="idx" :key="idx"
              :disabled="!item.items || item.items.length == 0">
              <template #title>
                <div class="sort-hd__title" @click.stop="onScenesCheck(item)">
                  {{ item.title }}
                </div>
              </template>
              <a class="sort-cell__title" href="javascript:;" v-for="(cell, index) in item.items" :key="index"
                @click.stop="onScenesCheck(cell)">{{ cell.title }}</a>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="sort-bd">
        <div class="sort-bd-table">
          <Goods :datas="goodsDatas" :source-data="goodsSourceData" @handleCurrentChange="handleCurrentChange">
            <template #table-hd>
              <h3 class="sort-bd-table-title">&nbsp;</h3>
            </template>
          </Goods>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { goodsList } from '@/api/goods'
import { goodsSortScenesSortList } from '@/api/app'
import { useRoute, useRouter } from 'vue-router'
import { ArrowRight } from '@element-plus/icons-vue'
import Goods from './components/goods.vue'
import { isArrayFn } from '@/utils'
const route = useRoute()
const router = useRouter()
const sortSourceDatas = ref([
  {
    id: 1,
    title: '酒店住宿',
    items: [
      {
        id: 1,
        title: '高端'
      },
      {
        id: 2,
        title: '中高端'
      },
      {
        id: 3,
        title: '经济型'
      }
    ]
  },
  {
    id: 2,
    title: '文旅景区',
    items: [
      {
        id: 1,
        title: '高端'
      },
      {
        id: 2,
        title: '中高端'
      },
      {
        id: 3,
        title: '经济型'
      }
    ]
  },
  {
    id: 3,
    title: '物业园区',
    items: [
      {
        id: 1,
        title: '高端'
      },
      {
        id: 2,
        title: '中高端'
      },
      {
        id: 3,
        title: '经济型'
      }
    ]
  },
  {
    id: 4,
    title: '政府政企',
    items: [
      {
        id: 1,
        title: '高端'
      },
      {
        id: 2,
        title: '中高端'
      },
      {
        id: 3,
        title: '经济型'
      }
    ]
  },
  {
    id: 5,
    title: '航空差旅',
    items: [
      {
        id: 1,
        title: '高端'
      },
      {
        id: 2,
        title: '中高端'
      },
      {
        id: 3,
        title: '经济型'
      }
    ]
  }
])
const sortDatas = ref({
  id: 1,
  title: '酒店住宿',
  items: [
    {
      id: 1,
      title: '高端'
    },
    {
      id: 2,
      title: '中高端'
    },
    {
      id: 3,
      title: '经济型'
    }
  ]
})
const brandTitle = ref('')
const current_parent_item = ref({})
const init = (alias) => { }
const goodsDatas = ref([])
const goodsSourceData = ref([])
const currentAlias = ref('')
const scenes_id_idx = ref(0)
const scenes_type_id_idx = ref(0)

const goodsInit = (page = 1, parmas) => {
  const { scenes_id, scenes_type_id, goods_sort_alias } = parmas
  goodsList({
    page,
    pageSize: 20,
    scenes_id,
    scenes_type_id,
    goods_sort_alias
  }).then((res) => {
    const { error, data } = res
    if (error === 0) {
      goodsSourceData.value = data
      const { items } = data
      goodsDatas.value = items
    }
  })
}
const activeNames = ref([])
const dataToList = (list) => {
  const lists = []
  for (let i = 0; i < list.length; i++) {
    const el = list[i]
    const item = el[0]
    item['items'] = [el[1]] || []
    const idx = lists.findIndex((n) => n.id == el[0].id)
    if (idx == -1) {
      lists.push(item)
    } else {
      lists[idx]['items'].push(el[1])
    }
  }
  return lists
}
const scenesSortList = ref([])
const goodsSortInit = (e) => {
  const { scenes_id, scenes_type_id } = e
  goodsSortScenesSortList({
    scenes_id,
    scenes_type_id
  }).then((res) => {
    const { error, data } = res
    const lists = []
    if (error == 0 && isArrayFn(data)) {
      data.map((n) => {
        if (n.length) {
          if (n[0]['id'] == 69 || n[0]['id'] == 415) {
            lists.push([n[n['length'] - 2] || '', n[n['length'] - 1] || ''])
          } else if (n[1]['id'] == 69 || n[1]['id'] == 415) {
            lists.push([n[n['length'] - 2] || '', n[n['length'] - 1] || ''])
          }
        }
      })
      scenesSortList.value = dataToList(lists)
    }
  })
}

watch(
  route,
  (e) => {
    const { scenes_id, scenes_type_id } = e?.query || {}
    scenes_type_id_idx.value = scenes_type_id - 1
    scenes_id_idx.value = scenes_id - 1
    sortDatas.value = sortSourceDatas.value[scenes_id_idx.value]
    goodsInit(1, { scenes_type_id, scenes_id })
    goodsSortInit({
      scenes_type_id,
      scenes_id
    })
  },
  { immediate: true }
)

const handleCurrentChange = (e) => {
  goodsInit(e, {
    scenes_type_id: scenes_type_id_idx.value + 1,
    scenes_id: scenes_id_idx.value + 1
  })
}
const onCheck = (item) => {
  const { id } = item || {}
  router.push({
    name: 'special',
    query: {
      scenes_type_id: id,
      scenes_id: scenes_id_idx.value + 1
    }
  })
}
const onScenesCheck = (item) => {
  const { alias } = item
  const { scenes_id, scenes_type_id } = route?.query || {}
  goodsInit(1, { scenes_type_id, scenes_id, goods_sort_alias: alias })
}
</script>

<style lang="scss" scoped>
.sort {
  &-hd {
    width: 200px;

    &__hd {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 16px;
      font-weight: 300;
      color: #191818;
      padding-bottom: 35px;
      margin-right: -40px;

      a {
        color: #191818;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
      color: #191818;
      display: block;
      width: 100%;
      margin: 10px 0;

      &:hover {
        text-decoration: underline;
      }
    }

    &__bd {
      font-size: 24px;
      font-weight: bold;
      color: #191818;
      // padding-bottom: 10px;
    }
  }

  &-bd {
    flex: 1;
    width: 75%;
    padding-left: 46px;

    &-radio {
      :deep(.el-radio-button) {
        --el-radio-button-checked-bg-color: transparent;
        --el-radio-button-checked-text-color: var(--el-color-primary);

        .el-radio-button__inner {
          background-color: transparent;
          border-radius: 0;
        }
      }
    }

    &-table {
      &-title {
        font-size: 16px;
        line-height: 43px;
        font-weight: 500;
        color: #3d3e3e;
        margin: 0;
        padding-left: 20px;
      }
    }
  }

  :deep(.el-collapse-item__content) {
    padding: 0;
  }

  :deep(.el-collapse-item__header) {
    border-bottom: 2px solid #5e5e5e;
  }

  :deep(.el-collapse) {

    // margin-left: -25px;
    .el-collapse {
      &-item {
        position: relative;

        &__header {
          // padding-left: 25px;
          position: relative;
        }

        // &__arrow {
        //   position: absolute;
        //   left: 0;
        //   top: 19.5px;
        //   background-color: #333;
        //   display: block;
        //   font-size: 8px;
        //   // margin-top: -5px;
        //   &::after {
        //     content: '';
        //     position: absolute;
        //     display: block;
        //     border: 0.5em solid #fff;
        //     border-bottom: 1em solid transparent;
        //     border-top: 0 solid transparent;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //   }
        //   &.is-active {
        //     transform-origin: center;
        //     transform: rotate(180deg);
        //   }
        // }
        &.is-disabled {
          .el-collapse-item {
            &__arrow {
              display: none;
            }

            &__header {
              cursor: pointer;
            }
          }

          .sort-hd__title {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &-cell {
    &__title {
      font-size: 15px;
      font-weight: 300;
      color: #191818;
      display: block;
      margin: 10px 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.brand {
  &-tag {
    display: flex;
    align-items: center;
    cursor: pointer;

    :deep(.el-tag--large) {
      font-size: 18px;
    }
  }
}
</style>
